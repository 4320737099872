* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ruleGroup {
  background: var(--kt-card-bg);
  border: none;
  padding: 20px 40px;
  margin: 20px 0px;
  border-radius: 0.425rem;
}

.ruleGroup-header {
  justify-content: end;
}

.ruleGroup-notToggle {
  font-size: 1.2rem;
  order: 3;
}

input[type="checkbox"] {
  margin-right: 6px;
}

.ruleGroup-combinators,
.rule-fields,
.rule-operators,
.rule-value {
  width: max-content;
}

.rule-remove {
  margin-left: 5px;
}

.rule {
  margin: 10px 0px;
}

.form-check-label {
  color: var(--kt-gray-800) !important;
}

.ruleGroup .addRule-section,
.ruleGroup-notToggle {
  display: none;
}

.ruleGroup .ruleGroup .addRule-section,
.ruleGroup .ruleGroup .ruleGroup-notToggle {
  display: flex;
  align-items: center;
}

.ruleGroup .ruleGroup .addRule-section i{
  right: 15px;
  top: 15px;
}

.ruleGroup-body {
  flex-wrap: wrap;
}

.form-control,
select {
  border: 1px solid var(--kt-text-black-30) !important;
  color: var(--kt-gray-900);
}

.ruleGroup-combinators {
  border: 1px solid #f1c40f !important;
  background-color: #f1c40f33;
  border-radius: 0.425rem !important;
}

.main-rules {
  border: 1px solid var(--kt-primary);
  border-radius: 0.425rem !important;
}

.ruleGroup-body .ruleGroup {
  background: var(--kt-card-bg);
  border: 1px solid var(--kt-text-black-20);
  padding: 30px !important;
}

.ruleGroup-body .ruleGroup-completed {
  border: 1px solid #07bc0c;
}

.ruleGroup-body .ruleGroup-progress {
  border: 1px solid #f1c40f;
}

.ruleGroup-addRule {
  padding: 8px 12px;
  border: none;
  border-radius: 0.425rem;
  background-color: var(--kt-primary);
  color: var(--kt-card-bg);
}

.ruleGroup-header .addRule-section {
  order: 1;
  margin-right: auto;
}

.queryBuilder {
  position: relative;
}

.ruleGroup-remove {
  order: 4;
}

.react-datepicker__input-container input {
  height: 35px;
  border-radius: 0.425rem;
  padding: 0px 10px;
  background: transparent;
  color: var(--kt-gray-900);
  border: 1px solid var(--kt-text-black-30) !important;
  color: var(--kt-gray-900);
}

.react-datepicker__input-container input:focus {
  outline: none !important;
}

.rule-value-list-item { 
  outline: none;
  padding: 7px 10px;
  border: 1px solid var(--kt-text-black-30);
  border-radius: 0.425rem;
}

span.rule-value, span.form-control, span.form-control-sm {
  border: none !important;
}

span.rule-value {
  display: flex;
  align-items: center;
}